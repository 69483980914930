import React from 'react';

const Pagination = ({ totalPosts, postsPerPage, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav className='mx-auto'>
      <ul className='pagination'>
        {pageNumbers.map((pageNumber) => {
          return (
            <li
              key={pageNumber}
              className={`page-item ${
                currentPage === pageNumber ? 'active' : null
              }`}
            >
              <button
                className='page-link'
                onClick={() => paginate(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
