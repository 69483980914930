import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ToolSearch from '../../components/UserDashboard/Tools/ToolSearch';
import waiting from '../../assets/waiting.svg';
import empty from '../../assets/empty.svg';
const Tools = ({ location }) => {
  const [docId, setDocId] = useState('');
  const [results, setResults] = useState([]);
  const [initialSearch, setInitialSearch] = useState('Waiting for Search');

  useEffect(() => {
    setDocId(location.search);
  }, [initialSearch]);

  function compare(a, b) {
    const bandA = a.lineNumber.toUpperCase();
    const bandB = b.lineNumber.toUpperCase();
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }
  const handleSearch = (result) => {
    result.sort(compare);
    setResults(result);
    if (result.length === 0) {
      setInitialSearch('No Results Found');
    }
  };
  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='search-width shadow-lg rounded mx-auto bg-white py-2'>
            <ToolSearch
              handleSearch={handleSearch}
              setInitialSearch={setInitialSearch}
            />
          </div>
        </div>
        <div className='row d-flex justify-content-center mt-5'>
          {results.length === 0 ? (
            <img
              src={empty}
              alt='waiting'
              className='img-fluid'
              width='500px'
            />
          ) : null}
        </div>
        <div className='text-center'>
          {results.length === 0 ? <h2>{initialSearch}</h2> : null}
        </div>

        {results.length > 0 ? (
          <div className='row'>
            <div className='table-responsive card'>
              <div className='card-body m-0 p-0'>
                <table className='table'>
                  <tbody>
                    <tr>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>Line Number</span>
                      </th>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>Size</span>
                      </th>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>Piping Class</span>
                      </th>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>P&ID</span>
                      </th>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>Location From</span>
                      </th>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>Location To</span>
                      </th>
                      <th scope='col' className='border-0'>
                        <span className='text-muted'>Status</span>
                      </th>
                    </tr>

                    {results.map((result) => {
                      return (
                        <tr key={result.id}>
                          <td>
                            <Link to={`/dashboard/line/${result.id}${docId}`}>
                              {result.lineNumber}
                            </Link>
                          </td>
                          <td>{result.size}</td>
                          <td>{result.pipingClass}</td>
                          <td>{result.pid}</td>
                          <td>{result.locationFrom}</td>
                          <td>{result.locationTo}</td>
                          <td>
                            {result.completed ? (
                              <span className='badge badge-pill badge-success p-2'>
                                Completed
                              </span>
                            ) : (
                              <span className='badge badge-pill badge-danger p-2'>
                                Pending
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withRouter(Tools);
