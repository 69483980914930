import React from 'react';

const DataTable = ({ results, docId }) => {
  return (
    <>
      {results.map((result, i) => {
        return (
          <tr key={i}>
            <td>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`/dashboard/line/${result.id}${docId}`}
              >
                {result.lineNumber}
              </a>
            </td>
            <td>{result.size}</td>
            <td>{result.pipingClass}</td>
            <td>{result.pid}</td>
            <td>{result.tagNo}</td>
            <td>{result.description}</td>
            <td>{result.remarks}</td>
            <td>
              {result.status ? (
                <span className='badge badge-pill badge-success p-2'>
                  Closed
                </span>
              ) : (
                <span className='badge badge-pill badge-danger p-2'>Open</span>
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default DataTable;
