import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import LineUpdate from './LineUpdate';

const Line = ({ match, location }) => {
  return (
    <>
      <Route
        path={`${match.path}/:id`}
        render={() => <LineUpdate key={location.key} />}
      />
    </>
  );
};

export default withRouter(Line);
