import React, { useState, useEffect, useContext } from 'react';
import { firestore } from '../../firebase/firebase.utils';
import { withRouter } from 'react-router-dom';
import pdfMake from 'pdfmake/build/pdfmake';
import moment from 'moment';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js';
import { saveAs } from 'file-saver';
import DataTable from '../../components/UserDashboard/CompletedDataTable';
import Pagination from '../../components/UserDashboard/Pagination';
import { Search } from 'react-feather';
import { ProjectContext } from '../../context/projectContext';

const Completed = ({ location }) => {
  const { projectName } = useContext(ProjectContext);
  const postsPerPage = 10;

  const [results, setResults] = useState([]);
  const [docId, setDocId] = useState('');
  const [initialValue, setInitialValue] = useState('all');
  const [loading, setLoading] = useState(true);
  const [currentLines, setCurrentLines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [exportButton, setExportButton] = useState({
    disabled: false,
    text: 'EXPORT PDF',
  });

  const allCompleted = () => {
    firestore
      .collection(projectName.toLowerCase())
      .where('completed', '==', true)
      .orderBy('lineNumber')
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          setResults(snapshot.docs);
          setCurrentLines(snapshot.docs);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    setDocId(location.search);

    if (projectName !== '') {
      allCompleted();
    }
  }, [projectName]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    let filterQuery = e.target.value;
    setSearchQuery(e.target.value);
    let reg = new RegExp(filterQuery.trim(), 'i');
    const filteredLines = results.filter((result) => {
      result = result.data();
      return (
        reg.test(result.lineNumber) ||
        reg.test(result.size) ||
        reg.test(result.pipingClass) ||
        reg.test(result.pid) ||
        reg.test(result.locationFrom) ||
        reg.test(result.locationTo)
      );
    });
    setCurrentLines(filteredLines);
  };

  const exportExcel = async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    ws.columns = [
      { key: 'id', width: 15 },
      { key: 'id', width: 10 },
      { key: 'id', width: 15 },
      { key: 'id', width: 10 },
      { key: 'id', width: 15 },
      { key: 'id', width: 15 },
      { key: 'id', width: 12 },
    ];

    ws.mergeCells('A1:G1');
    ws.getCell('A1').value = 'PADINK ENGINEERING SERVICES PRIVATE LIMITED';
    ws.getCell('A1').font = {
      size: 14,
      bold: true,
    };

    ws.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

    ws.mergeCells('A2:C2');
    ws.mergeCells('D2:G2');

    ws.getCell('A2').value = 'PROJECT NAME: NOLA';
    ws.getCell('F2').value = `GENERATED DATE: ${moment().format(
      'DD-MM-YYYY,h:mm a'
    )}`;
    ws.getCell('A2').font = {
      size: 12,
      bold: true,
    };
    ws.getCell('D2').font = {
      size: 12,
      bold: true,
    };

    ws.mergeCells('A3:G3');
    ws.getCell('A3').value = `${
      initialValue === 'all' ? '' : `STAGE-${initialValue}`
    } COMPLETED LINES`;

    ws.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getCell('A3').font = {
      size: 12,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };
    ws.getCell('A3').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF08080' },
      bgColor: { argb: 'FFF08080' },
    };

    const row = ws.addRow([
      'Line Number',
      'Size',
      'Piping Class',
      'P&ID',
      'Location From',
      'Location To',
      'Status',
    ]);
    row.font = {
      color: { argb: 'FFFFFFFF' },
      bold: true,
      size: 13,
    };
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF6495ED' },
      bgColor: { argb: 'FF6495ED' },
    };

    results.forEach((result, i) => {
      result = result.data();
      ws.addRow([
        result.lineNumber,
        result.size,
        result.pipingClass,
        result.pid,
        result.locationFrom,
        result.locationTo,
        result.completed ? 'Completed' : 'Pending',
      ]);
      ws.getCell(`G${5 + i}`).font = {
        color: { argb: result.completed ? 'FF008000' : 'FFB22222' },
      };
    });

    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'Report.xlsx');
  };

  const exportPDF = () => {
    setExportButton({
      ...exportButton,
      text: 'Generating...',
      disabled: true,
    });
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };
    var docDefinition = {
      pageSize: 'A4',
      defaultStyle: {
        font: 'Roboto',
      },
      info: {
        title: 'Project Nola Report',
        author: 'Padink Engineering Services Private Limited',
        subject: 'Completed Status Report',
      },
      watermark: {
        text: 'PADINK',
        color: 'blue',
        opacity: 0.1,
        bold: true,
        fontSize: 100,
      },

      footer: {
        columns: [
          {
            text: 'POWERED BY PADINK ENGINEERING IT SERVICES',
            alignment: 'center',
            color: '#6c757d',
            fontSize: 12,
          },
        ],
      },
      content: [
        { text: '', margin: [0, 40, 0, 0] },
        {
          columns: [
            {
              width: '*',
              text: [
                { text: 'Project Name: ', bold: true },
                { text: 'Nola', color: '#6c757d' },
              ],
            },
            {
              width: '*',
              text: [
                { text: 'Generation Date: ', bold: true },
                {
                  text: moment().format('DD-MM-YYYY,h:mm a'),
                  color: '#6c757d',
                },
              ],
            },
          ],
        },
        {
          text: `${
            initialValue === 'all' ? '' : `STAGE-${initialValue}`
          } COMPLETED LINES`,
          alignment: 'center',
          fontSize: 15,
          bold: true,
          decoration: 'underline',
          margin: [0, 40, 0, 0],
        },
        {
          layout: 'lightHorizontalLines',
          margin: [0, 20, 0, 0],

          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
              [
                { text: 'Line Number', bold: true },
                { text: 'Size', bold: true },
                { text: 'Piping Class', bold: true },
                { text: 'P&ID', bold: true },
                { text: 'Location From', bold: true },
                { text: 'Location To', bold: true },
                { text: 'Status', bold: true },
              ],
            ],
          },
        },
      ],
    };

    results.forEach((result) => {
      let data = result.data();
      docDefinition.content[3].table.body.push([
        data.lineNumber,
        data.size,
        data.pipingClass,
        data.pid,
        data.locationFrom,
        data.locationTo,
        {
          text: data.completed ? ' Completed ' : 'Pending',
          fillColor: data.completed ? 'green' : 'red',
          color: 'white',
          fontSize: 10,
        },
      ]);
    });

    pdfMake
      .createPdf(docDefinition)
      .download(
        `${
          initialValue === 'all' ? 'Completed' : `STAGE-${initialValue}`
        } Report`
      );
    setExportButton({
      ...exportButton,
      text: 'EXPORT PDF',
      disabled: false,
    });
  };

  const filterOptions = (e) => {
    setLoading(true);
    let evalue = e.target.value;
    setInitialValue(evalue);

    if (evalue === 'all') {
      allCompleted();
    } else {
      firestore
        .collection(projectName.toLowerCase())
        .orderBy('lineNumber')
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            let completed = [];
            snapshot.forEach((doc) => {
              if (doc.data().stages[evalue].completed) {
                completed.push(doc);
              }
            });
            setResults(completed);
            setLoading(false);
            setCurrentLines(completed);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className='container my-3'>
      <div className='row justify-content-between'>
        <form className='form-inline'>
          <div className='form-group mb-2'>
            <label htmlFor='Filter' className='sr-only'>
              Filter
            </label>
            <select
              className='form-control'
              id='Filter'
              onChange={filterOptions}
              value={initialValue}
            >
              <option value='all'>COMPLETED</option>
              <option value='0'>STAGE-0</option>
              <option value='1'>STAGE-1</option>
              <option value='2'>STAGE-2</option>
              <option value='3'>STAGE-3</option>
              <option value='4'>STAGE-4</option>
              <option value='5'>STAGE-5</option>
              <option value='6'>STAGE-6</option>
            </select>
          </div>
        </form>
        {!loading ? (
          <div>
            <button
              className='btn btn-darkgreen btn-sm px-4 py-2'
              onClick={exportPDF}
              disabled={exportButton.disabled}
            >
              {exportButton.text}
            </button>

            <button
              className='ml-2 btn btn-darkgreen btn-sm px-4 py-2'
              onClick={exportExcel}
            >
              EXPORT EXCEL
            </button>
          </div>
        ) : null}
      </div>
      <div className='row mt-2'>
        <div className='table-responsive card border-rounded-corner'>
          <div className='card-body m-0 p-0'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='col-md-4 pt-3'>
                <form>
                  <div className='form-group icon-input'>
                    <Search size={24} className='feather' />
                    <input
                      type='text'
                      className='form-control'
                      id='search'
                      placeholder='search'
                      autoComplete='search'
                      name='search'
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </form>
              </div>
              <div className='pr-3 pt-3'>
                <h6 className='text-muted'>
                  {currentLines.length} line(s) found
                </h6>
              </div>
            </div>
            {!loading ? (
              <table className='table'>
                <tbody>
                  <tr>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>Line Number</span>
                    </th>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>Size</span>
                    </th>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>Piping Class</span>
                    </th>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>P&ID</span>
                    </th>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>Location From</span>
                    </th>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>Location To</span>
                    </th>
                    <th scope='col' className='border-0'>
                      <span className='text-muted'>Status</span>
                    </th>
                  </tr>
                  <DataTable
                    docId={docId}
                    results={currentLines.slice(
                      indexOfFirstPost,
                      indexOfLastPost
                    )}
                    loading={loading}
                  />
                </tbody>
              </table>
            ) : (
              <div className='text-center'>Loading ...</div>
            )}
          </div>
          {currentLines.length < 1 && !loading ? (
            <h5 className='text-center'>No Results Found</h5>
          ) : null}
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={currentLines.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Completed);
