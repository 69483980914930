import React, { useEffect, useContext } from 'react';
import Chart from 'chart.js';
import { firestore } from '../../../firebase/firebase.utils';
import { ProjectContext } from '../../../context/projectContext';

const CompletedChart = () => {
  const { projectName } = useContext(ProjectContext);
  useEffect(() => {
    if (projectName !== '') {
      firestore
        .collection(projectName.toLowerCase())
        .where('completed', '==', true)
        .get()
        .then(async (snapshot) => {
          let completedCount = 0;
          let notCompletedCount = 0;
          if (!snapshot.empty) {
            completedCount = snapshot.docs && snapshot.docs.length;
          }

          let notCompletedSnapshot = await firestore
            .collection(projectName.toLowerCase())
            .where('completed', '==', false)
            .get();

          if (!notCompletedSnapshot.empty) {
            notCompletedCount =
              notCompletedSnapshot.docs && notCompletedSnapshot.docs.length;
          }

          var ctx = document.getElementById('completedChart');
          let data = {
            datasets: [
              {
                data: [completedCount, notCompletedCount],
                backgroundColor: ['#073134', '#340a07'],
              },
            ],
            labels: ['completed', 'Not Completed'],
          };
          if (ctx) {
            var myChart = new Chart(ctx, {
              type: 'pie',
              data: data,
            });
          }
        });
    }
  }, [projectName]);
  return (
    <div className='col-md-6'>
      <div className='card py-4 dashboard-border-rounded px-2'>
        <h3 className='mx-auto font-weight-bold'>Completed Status</h3>
        <canvas id='completedChart' width='150' height='75'></canvas>
      </div>
    </div>
  );
};

export default CompletedChart;
