import React, { useState, useEffect } from 'react';
import logo from '../../assets/LOGO.svg';
import { auth, firestore } from '../../firebase/firebase.utils';
import { withRouter, Link } from 'react-router-dom';

const ProjectSelect = ({ history }) => {
  const [logoutError, setLogoutError] = useState(false);
  const [projectLoading, setProjectLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    firestore
      .collection('projects')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          console.log('No projects found');
          return;
        }
        let projectListAfterLoad = [];
        snapshot.forEach((doc) => {
          projectListAfterLoad.push({
            id: doc.id,
            projectName: doc.data().projectName,
          });
        });
        setProjectList(projectListAfterLoad);
        setProjectLoading(false);
      });
  }, []);

  const handleSignout = () => {
    auth
      .signOut()
      .then(() => {
        history.push('/');
      })
      .catch((err) => {
        setLogoutError(true);
      });
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12 col-md-7 col-lg-4 mx-auto my-auto'>
          <div className='my-5'>
            <div className='text-center'>
              <img src={logo} alt='company logo' />
            </div>
            <h3 className='text-center'>ENGINEERING TRACKER</h3>
          </div>

          <p className='small-heading text-muted mb-2'>Select Project</p>
          <div className='list-group mb-4'>
            {projectLoading ? (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              projectList.map((project, i) => {
                return (
                  <Link
                    key={i}
                    className='list-group-item list-group-item-action d-flex align-items-center p-2'
                    to={`/dashboard?pid=${project.id}`}
                  >
                    <div className='avatar mr-2'>
                      <span className='avatar-initial'>
                        {project.projectName[0]}
                      </span>
                    </div>
                    <div className='text-truncate'>{project.projectName}</div>
                  </Link>
                );
              })
            )}
          </div>
          <button
            className='btn btn-block btn-danger text-uppercase'
            onClick={handleSignout}
          >
            Logout
          </button>
          {logoutError ? (
            <div className='alert alert-warning' role='alert'>
              Error occurred while signing out, please refresh the page and try
              again.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProjectSelect);
