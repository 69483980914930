import React from 'react';

const DataTable = ({ results, docId }) => {
  return (
    <>
      {results.length > 0
        ? results.map((result, i) => {
            let id = result.id;
            result = result.data();

            return (
              <tr key={i}>
                <td>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`/dashboard/line/${id}${docId}`}
                  >
                    {result.lineNumber}
                  </a>
                </td>
                <td>{result.size}</td>
                <td>{result.pipingClass}</td>
                <td>{result.pid}</td>
                <td>{result.locationFrom}</td>
                <td>{result.locationTo}</td>
                <td>
                  {result.completed ? (
                    <span className='badge badge-pill badge-success p-2'>
                      Closed
                    </span>
                  ) : (
                    <span className='badge badge-pill badge-danger p-2'>
                      Open
                    </span>
                  )}
                </td>
              </tr>
            );
          })
        : null}
    </>
  );
};

export default DataTable;
