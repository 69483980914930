import React, { useState, useEffect, useContext } from 'react';
import CommentDisplay from './CommentDisplay';
import { firestore } from '../../../firebase/firebase.utils';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import { AuthContext } from '../../../components/Auth/Auth';
import { ProjectContext } from '../../../context/projectContext';
import swal from 'sweetalert';

const Comment = ({ location }) => {
  const { projectName } = useContext(ProjectContext);

  const [message, setMessage] = useState('');
  const [docid, setDocId] = useState('');
  const [allComments, setAllComments] = useState([]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (projectName !== '') {
      const docId = location.pathname.split('/')[3];
      setDocId(docId);
      firestore
        .collection(projectName.toLowerCase())
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAllComments(doc.data().comments);
          }
        });
    }
  }, [location.pathname, projectName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message !== '' || message.trim() !== '') {
      let username = await (
        await firestore.collection('users').doc(user.currentUser.uid).get()
      ).data().userName;

      let newComment = {
        message,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: username,
      };
      await firestore
        .collection(projectName.toLowerCase())
        .doc(docid)
        .update({
          comments: firebase.firestore.FieldValue.arrayUnion(newComment),
        });
      setAllComments([...allComments, newComment]);
      setMessage('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const handleCommentDelete = async (i) => {
    swal({
      title: 'Are you sure?',
      text: `This comment will be deleted. Are you sure ?`,
      icon: 'warning',
      buttons: true,
    }).then(async (ok) => {
      if (ok) {
        let allCommentsLocal = [...allComments];
        allCommentsLocal.splice(i, 1);
        try {
          await firestore
            .collection(projectName.toLowerCase())
            .doc(docid)
            .update({
              comments: allCommentsLocal,
            });
        } catch (err) {
          console.log(err);
          return;
        }

        setAllComments(allCommentsLocal);
      }
    });
  };
  return (
    <div className='row'>
      <div className='col-md-8'>
        <form onSubmit={handleSubmit}>
          <textarea
            name='message'
            id='comment'
            className='form-control comment-box'
            required
            spellCheck='false'
            placeholder='Leave a comment'
            rows='2'
            value={message}
            onChange={handleChange}
          ></textarea>
          <div className='d-flex justify-content-end mb-4 mt-2'>
            <button type='submit' className='btn btn-darkgreen btn-lg'>
              Comment
            </button>
          </div>
        </form>
        <CommentDisplay
          handleCommentDelete={handleCommentDelete}
          allComments={allComments}
        />
      </div>
    </div>
  );
};

export default withRouter(Comment);
