import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { firestore } from '../firebase/firebase.utils';
export const ProjectContext = React.createContext();

const Project = ({ location, children, history }) => {
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    firestore
      .collection('projects')
      .doc(`${location.search.split('=')[1]}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProjectName(doc.data().projectName);
        } else {
          history.push('/');
        }
      });
  }, [projectName]);
  return (
    <ProjectContext.Provider value={{ projectName }}>
      {children}
    </ProjectContext.Provider>
  );
};

export default withRouter(Project);
