import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'react-feather';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const LineStages = ({ lineData, markComplete }) => {
  const [stageData, setStageData] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (lineData) {
      setStageData(lineData);
    }
    return;
  }, [lineData]);

  const handleComplete = (stageNumber) => {
    setDisableButton(true);
    markComplete(stageNumber, setDisableButton);
  };

  return (
    <div className='bg-white py-3 px-3 rounded my-3'>
      <h3>Stages</h3>
      {stageData.length != 0 ? (
        stageData.map((stage, i) => {
          return (
            <React.Fragment key={i}>
              <div className='form-group row'>
                <label
                  className='col-form-label col-lg-2 col-md-6 col-12'
                  htmlFor='stage1'
                >
                  <span className='font-weight-bolder'> {`STAGE - ${i}`}</span>
                </label>
                <label
                  className='col-form-label col-lg-3 col-md-6 col-12'
                  htmlFor='stage1'
                >
                  {stage.description}
                </label>
                <div className='col-lg-2 col-md-6 col-12 text-md-center'>
                  {stage.completed ? (
                    <span>
                      <CheckCircle
                        data-tip
                        data-for='completed'
                        size={24}
                        color='#073134'
                      />
                      <ReactTooltip id='completed'>
                        <span>completed</span>
                      </ReactTooltip>
                    </span>
                  ) : (
                    <button
                      type='button'
                      disabled={disableButton}
                      className='btn btn-warning'
                      onClick={() => handleComplete(stage.stageNumber)}
                    >
                      Mark as Complete
                    </button>
                  )}
                </div>
                <div className='col-lg-5 col-md-6 col-12'>
                  <p className='text-muted col-form-label'>
                    {stage.completed
                      ? `By ${stage.userName} on ${moment
                          .unix(stage.completedDate.seconds)
                          .format('Do MMMM YYYY')}`
                      : `Stage added by Admin`}
                  </p>
                </div>
              </div>
              <div className='row d-md-none line-seperator'></div>
            </React.Fragment>
          );
        })
      ) : (
        <div className='text-center'>
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LineStages;
