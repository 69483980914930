import React, { useContext } from 'react';
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import AutoComplete from './AutoComplete';
import { auth } from '../../firebase/firebase.utils';
import { ProjectContext } from '../../context/projectContext';

let firstLoad = true;

const algoliaClient = algoliasearch(
  '6ZN7J6KLDZ',
  '278e947b02011ab209373ca092109c44'
);

const searchClient = {
  search(requests) {
    if (firstLoad === true) {
      firstLoad = false;
      return;
    }
    return algoliaClient.search(requests);
  },
};

const Header = ({ history }) => {
  const { projectName } = useContext(ProjectContext);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        history.push('/');
      })
      .catch((err) => {
        console.log('error :', err);
      });
  };
  return (
    <header className='navbar navbar-expand-lg navbar-dark py-2 px-3 w-100'>
      <div className='container d-block'>
        <div className='row'>
          <div className='col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between'>
            <ul className='nav nav-pills sort-wrapper'></ul>

            <ul className='navbar-nav flex-row align-items-center justify-content-end'>
              <li className='nav-item d-none d-md-block mr-3'>
                <InstantSearch
                  indexName={`${projectName.toLowerCase()}`}
                  searchClient={searchClient}
                >
                  <Configure hitsPerPage={5} />

                  <AutoComplete />
                </InstantSearch>
              </li>
              <li className='nav-item flex-center'>
                <button
                  onClick={handleLogout}
                  type='button'
                  className='btn btn-danger mt-md-n3 mt-0'
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
