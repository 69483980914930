import React from 'react';
import Layout from '../../components/UserDashboard/Layout';
import { Route } from 'react-router-dom';
import UploadPage from './Upload.page';
import Line from './Line';
import Tools from './Tools';
import DashboardPage from './Dashboard.page';
import Completed from './Completed';
import Reports from './Reports';
import ProjectProvider from '../../context/projectContext';

const Dashboard = ({ match }) => {
  return (
    <ProjectProvider>
      <Layout>
        <Route exact path={`${match.path}`} component={DashboardPage} />
        <Route path={`${match.path}/upload`} component={UploadPage} />
        <Route path={`${match.path}/tools`} component={Tools} />
        <Route path={`${match.path}/line`} component={Line} />
        <Route path={`${match.path}/completed`} component={Completed} />
        <Route path={`${match.path}/reports`} component={Reports} />
      </Layout>
    </ProjectProvider>
  );
};

export default Dashboard;
