import React, { useState, useEffect } from 'react';

const LineDetails = ({ handleSheetUpdate, lineData }) => {
  const [lineDetails, setLineDetails] = useState({ noOfSheets: 0 });
  const [modalNoSheets, setModalNoSheets] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (Object.keys(lineData).length === 0 && lineData.constructor === Object) {
      return;
    }
    setLineDetails(lineData);
  }, [lineData]);

  const handleChange = (event) => {
    const { value } = event.target;
    setModalNoSheets(value);
  };

  const handleSubmit = (event) => {
    setDisableButton(true);
    setLineDetails({ ...lineDetails, noOfSheets: parseInt(modalNoSheets, 10) });
    handleSheetUpdate(parseInt(modalNoSheets, 10))
      .then((res) => setDisableButton(false))
      .catch((err) => setShowError(true));
  };
  return (
    <div className='bg-white py-3 px-3 rounded position-relative'>
      <div
        className='modal fade'
        id='sheetsModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='sheetsModal'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4
                className='modal-title font-weight-bolder'
                id='sheetsModalLabel'
              >
                Modify No of Sheets/ISOs
              </h4>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='container'>
                <div className='form-group row'>
                  <div className='col-md-12'>
                    <input
                      type='number'
                      onChange={handleChange}
                      value={modalNoSheets}
                      placeholder='enter no of sheets'
                      className='form-control'
                      name='modalnoOfSheets'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='submit'
                disabled={disableButton}
                className='btn btn-darkgreen'
                onClick={handleSubmit}
              >
                Update
              </button>
              {showError ? (
                <p className='text-danger'>
                  Error occured, check your internet connectivity. please try
                  again after sometime.
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <span className='h3'>Line Details</span>
      {Object.keys(lineDetails).length > 1 ? (
        <>
          <span className='badge badge-secondary status'>
            {lineDetails.completed ? 'Completed' : 'Not Completed'}
          </span>
          <div className='row mt-3'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Project Name</label>
                <input
                  type='text'
                  value={lineDetails.projectName}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Line Number</label>
                <input
                  type='text'
                  value={lineDetails.lineNumber}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Location From</label>
                <input
                  type='text'
                  value={lineDetails.locationFrom}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Location To</label>
                <input
                  type='text'
                  value={lineDetails.locationTo}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Fluid Code</label>
                <input
                  type='text'
                  value={lineDetails.fluidCode}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
          </div>
          <div className='row d-flex'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Insulation</label>
                <input
                  type='text'
                  value={lineDetails.insulation}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>Piping Class</label>
                <input
                  type='text'
                  value={lineDetails.pipingClass}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>P&ID</label>
                <input
                  type='text'
                  value={lineDetails.pid}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6'>
              <div className='form-group'>
                <label htmlFor='pname'>Size</label>
                <input
                  type='text'
                  value={lineDetails.size}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-lg-2 col-md-6 col-6'>
              <div className='form-group'>
                <label htmlFor='pname'>Area</label>
                <input
                  type='text'
                  value={lineDetails.area}
                  disabled
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12'>
              <div className='form-group'>
                <label htmlFor='pname'>No of Sheets/ISOs</label>
                <div className='input-group mb-3'>
                  <input
                    name='noOfSheets'
                    type='number'
                    value={lineDetails.noOfSheets}
                    disabled
                    className='form-control'
                    placeholder='sheets'
                    aria-label='Username'
                    aria-describedby='basic-addon1'
                  />
                  <div className='input-group-append'>
                    <button
                      className='input-group-text'
                      data-toggle='modal'
                      data-target='#sheetsModal'
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='text-center'>
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LineDetails;
