import React from 'react';
import Login from '../pages/login';
import Dashboard from '../pages/Dashboard/Dashboard';
import ProjectSelect from '../pages/ProjectSelect/ProjectSelect';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

const Routes = [
  {
    path: '/',
    key: 'LOGIN',
    exact: true,
    private: false,
    component: Login,
  },
  {
    path: '/manage/project',
    key: 'PROJECT SELECT',
    exact: false,
    private: true,
    component: ProjectSelect,
  },

  {
    path: '/dashboard',
    key: 'DASHBOARD',
    exact: false,
    private: true,
    component: Dashboard,
  },
];
export default Routes;

function RouteWithSubRoutes(route) {
  return !route.private ? (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  ) : (
    <PrivateRoute
      path={route.path}
      exact={route.exact}
      component={route.component}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
    </Switch>
  );
}
