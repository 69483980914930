import React, { useState, useEffect, useContext } from 'react';
import { firestore } from '../../firebase/firebase.utils';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js';
import { saveAs } from 'file-saver';
import moment from 'moment';
import DataTable from '../../components/UserDashboard/DataTable';
import Pagination from '../../components/UserDashboard/Pagination';
import { Search } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { ProjectContext } from '../../context/projectContext';

const Reports = ({ location }) => {
  const { projectName } = useContext(ProjectContext);
  const postsPerPage = 10;

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [docId, setDocId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentLines, setCurrentLines] = useState([]);
  const [sortCondition, setSortCondition] = useState('ASC');

  useEffect(() => {
    setDocId(location.search);
  }, [location.search]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = (e) => {
    let filterQuery = e.target.value;
    setSearchQuery(e.target.value);
    let reg = new RegExp(filterQuery.trim(), 'i');
    const filteredLines = results.filter((result) => {
      return (
        reg.test(result.lineNumber) ||
        reg.test(result.size) ||
        reg.test(result.pipingClass) ||
        reg.test(result.pid) ||
        reg.test(result.tagNo)
      );
    });
    setCurrentLines(filteredLines);
  };
  const handleSort = (field) => {
    console.log(field);

    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA =
        typeof a[field] === 'string' ? a[field].toUpperCase() : a[field];
      const bandB =
        typeof b[field] === 'string' ? b[field].toUpperCase() : b[field];

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
    if (sortCondition === 'ASC') {
      currentLines.sort(compare);
      setSortCondition('DESC');
    } else {
      currentLines.reverse();
      setSortCondition('ASC');
    }
  };
  const viewHoldList = () => {
    setLoading(true);

    let holdData = [];
    firestore
      .collection(projectName.toLowerCase())
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.docs.forEach((doc) => {
            let result = doc.data();
            if (result.hold && result.hold.length > 0) {
              result.hold.forEach((data) => {
                holdData.push({
                  id: doc.id,
                  lineNumber: result.lineNumber,
                  size: result.size,
                  pipingClass: result.pipingClass,
                  pid: result.pid,
                  tagNo: data.tagno,
                  description: data.description,
                  remarks: data.remarks,
                  status: data && data.status ? true : false,
                });
              });
            }
          });
          setResults(holdData);
          setLoading(false);
          setCurrentLines(holdData);
        }
      });
  };

  const generateHoldExcel = async () => {
    let holdData = [];
    firestore
      .collection(projectName.toLowerCase())
      .get()
      .then(async (snapshot) => {
        if (!snapshot.empty) {
          snapshot.docs.forEach((doc) => {
            let result = doc.data();
            if (result.hold && result.hold.length > 0) {
              result.hold.forEach((data) => {
                holdData.push({
                  id: doc.id,
                  lineNumber: result.lineNumber,
                  size: result.size,
                  pipingClass: result.pipingClass,
                  pid: result.pid,

                  tagNo: data.tagno,
                  description: data.description,
                  remarks: data.remarks,
                  status: data && data.status ? true : false,
                });
              });
            }
          });

          setResults(holdData);
          const wb = new ExcelJS.Workbook();
          const ws = wb.addWorksheet();
          ws.columns = [
            { key: 'id', width: 13 },
            { key: 'id', width: 8 },
            { key: 'id', width: 14 },
            { key: 'id', width: 14 },
            { key: 'id', width: 20 },
            { key: 'id', width: 35 },
            { key: 'id', width: 25 },
            { key: 'id', width: 12 },
          ];

          ws.mergeCells('A1:H1');
          ws.getCell('A1').value =
            'PADINK ENGINEERING SERVICES PRIVATE LIMITED';
          ws.getCell('A1').font = {
            size: 14,
            bold: true,
          };

          ws.getCell('A1').alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };

          ws.mergeCells('A2:E2');
          ws.mergeCells('F2:H2');

          ws.getCell('A2').value = 'PROJECT NAME: NOLA';
          ws.getCell('F2').value = `GENERATED DATE: ${moment().format(
            'DD-MM-YYYY,h:mm a'
          )}`;
          ws.getCell('A2').font = {
            size: 12,
            bold: true,
          };
          ws.getCell('F2').font = {
            size: 12,
            bold: true,
          };

          ws.mergeCells('A3:H3');
          ws.getCell('A3').value = `HOLD LIST REPORT`;
          ws.getCell('A3').alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          ws.getCell('A3').font = {
            size: 12,
            bold: true,
            color: { argb: 'FFFFFFFF' },
          };
          ws.getCell('A3').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF08080' },
            bgColor: { argb: 'FFF08080' },
          };

          const row = ws.addRow([
            'Line Number',
            'Size',
            'Piping Class',
            'P&ID',
            'Tag No',
            'Description',
            'Remarks',
            'Status',
          ]);

          row.font = {
            color: { argb: 'FFFFFFFF' },
            bold: true,
            size: 13,
          };
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF6495ED' },
            bgColor: { argb: 'FF6495ED' },
          };

          holdData.forEach((result, i) => {
            ws.addRow([
              result.lineNumber,
              result.size,
              result.pipingClass,
              result.pid,
              result.tagNo,
              result.description,
              result.remarks,
              result.status ? 'Closed' : 'Open',
            ]);
            ws.getCell(`E${5 + i}`).alignment = { wrapText: true };
            ws.getCell(`F${5 + i}`).alignment = { wrapText: true };
            ws.getCell(`G${5 + i}`).alignment = { wrapText: true };
            ws.getCell(`H${5 + i}`).font = {
              color: { argb: result.status ? 'FF008000' : 'FFB22222' },
            };
          });

          const buf = await wb.xlsx.writeBuffer();
          saveAs(new Blob([buf]), 'HOLD LIST REPORT.xlsx');
        }
      });
  };
  return (
    <div className='container'>
      <div className='row mt-4'>
        <div className='col-sm-12 col-md-7 col-lg-4 mx-auto my-auto'>
          <div className='card card-body'>
            <h5 className='card-title'>Report Generation</h5>
            <select className='form-control form-control-lg mr-3'>
              <option>Hold List</option>
            </select>
            <button
              className='mt-2 btn btn-primary btn-sm px-4 py-2'
              onClick={viewHoldList}
            >
              VIEW
            </button>
            <button
              className='mt-2 btn btn-darkgreen btn-sm px-4 py-2'
              onClick={generateHoldExcel}
            >
              Generate
            </button>
          </div>
        </div>
      </div>
      {!loading ? (
        <div className='row mt-2'>
          <div className='table-responsive card border-rounded-corner'>
            <div className='card-body m-0 p-0'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='col-md-4 pt-3'>
                  <form>
                    <div className='form-group icon-input'>
                      <Search size={24} className='feather' />
                      <input
                        type='text'
                        className='form-control'
                        id='search'
                        placeholder='search'
                        autoComplete='search'
                        name='search'
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div>
                <div className='pr-3 pt-3'>
                  <h6 className='text-muted'>
                    {currentLines.length} line(s) found
                  </h6>
                </div>
              </div>

              <table className='table'>
                <tbody>
                  <tr>
                    <th
                      onClick={() => handleSort('lineNumber')}
                      scope='col'
                      className='border-0 py-4 cp'
                    >
                      <span className='text-muted'>Line Number</span>
                    </th>
                    <th
                      onClick={() => handleSort('size')}
                      scope='col'
                      className='border-0 py-4 cp'
                    >
                      <span className='text-muted'>Size</span>
                    </th>
                    <th
                      onClick={() => handleSort('pipingClass')}
                      scope='col'
                      className='border-0 py-4 cp'
                    >
                      <span className='text-muted'>Piping Class</span>
                    </th>
                    <th
                      onClick={() => handleSort('pid')}
                      scope='col'
                      className='border-0 py-4 cp'
                    >
                      <span className='text-muted'>P&ID</span>
                    </th>
                    <th
                      onClick={() => handleSort('tagNo')}
                      scope='col'
                      className='border-0 py-4 cp'
                    >
                      <span className='text-muted'>Tag No</span>
                    </th>
                    <th scope='col' className='border-0 py-4'>
                      <span className='text-muted'>Description</span>
                    </th>
                    <th scope='col' className='border-0 py-4'>
                      <span className='text-muted'>Remarks</span>
                    </th>
                    <th
                      onClick={() => handleSort('status')}
                      scope='col'
                      className='border-0 py-4 cp'
                    >
                      <span className='text-muted'>Status</span>
                    </th>
                  </tr>
                  <DataTable
                    docId={docId}
                    results={currentLines.slice(
                      indexOfFirstPost,
                      indexOfLastPost
                    )}
                    loading={loading}
                  />
                </tbody>
              </table>
            </div>
            {currentLines.length < 1 && !loading ? (
              <h5 className='text-center'>No Results Found</h5>
            ) : null}
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={currentLines.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(Reports);
