import React from 'react';

import './App.scss';
import 'jquery';
import 'bootstrap';
import { AuthProvider } from './components/Auth/Auth';
import routes, { RenderRoutes } from './components/Routes';

function App() {
  return (
    <AuthProvider>
      <RenderRoutes routes={routes} />
    </AuthProvider>
  );
}

export default App;
