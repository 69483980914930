import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import tick from '../../assets/tick.gif';
import { firestore } from '../../firebase/firebase.utils';
import { AuthContext } from '../Auth/Auth';
import { ProjectContext } from '../../context/projectContext';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import algoliasearch from 'algoliasearch';

const FileUploadWrapper = styled.div`
  height: 300px;
  background-color: #ffffff;
  width: 50%;
  margin: auto;
  border-radius: 20px;
  margin-top: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 15px;
  @media (max-width: 991.98px) {
    width: 90% !important;
  }
  @media (max-width: 767.98px) {
    width: 100% !important;
  }
`;

const UploadDivider = styled.div`
  border: 2px dashed #cccccc;
  border-radius: 10px;
  height: 100%;
  display: ${(props) => (props.loading === 'true' ? 'none !important' : null)};
`;

const Upload = ({ location }) => {
  const { projectName } = useContext(ProjectContext);

  const client = algoliasearch(
    '6ZN7J6KLDZ',
    'f83b7373addf4abe98f6ede854e7b9e3'
  );
  const algoliaIndex = client.initIndex(projectName.toLowerCase());

  // for verifying the format of file uploaded
  const [fileTypeError, setFileTypeError] = useState(false);
  // loading after file upload
  const [loading, setLoading] = useState(false);
  // for showing processing message
  const [processing, setProcessing] = useState(false);

  const { user } = useContext(AuthContext);

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);
    // Check whether excel file is uploaded
    if (
      acceptedFiles[0].type === `application/vnd.ms-excel`.trim() ||
      acceptedFiles[0].type ===
        `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    `.trim()
    ) {
      var reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheet_name = workbook.SheetNames[0];
        let sheet_json = await XLSX.utils.sheet_to_json(
          workbook.Sheets[sheet_name]
        );
        const batch = firestore.batch();
        const { uid } = user.currentUser;

        sheet_json.forEach(async (line, index) => {
          let lineRef = firestore.collection(projectName.toLowerCase()).doc();
          console.log(line);
          algoliaIndex.saveObject(
            {
              lineNumber: line.LINE_NUMBER.trim(),
              lineId: lineRef.id,
            },
            {
              autoGenerateObjectIDIfNotExist: true,
            }
          );
          batch.set(lineRef, {
            lineNumber: line.LINE_NUMBER.toString().trim(),
            fluidCode: line.FLUID_CODE.toString().trim(),
            pipingClass: line.PIPING_CLASS.toString().trim(),
            completed: false,
            size: parseInt(line.SIZE) || '',
            locationFrom: line.LOCATION_FROM || '',
            locationTo: line.LOCATION_TO || '',
            pid: parseInt(line.PID) || '',
            noOfSheets: line.NO_OF_SHEETS,
            projectName: line.PROJECT_NAME.toString().trim(),
            projectCode: line.PROJECT_CODE.toString().trim(),
            insulation: line.INSULATION.toString().trim(),
            area: line.AREA.toString().trim(),
            hold: [],
            comments: [],
            stages: [
              {
                stageNumber: 1,
                description: '3D Modelling Completed',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
              {
                stageNumber: 2,
                description: 'Main Routing as per P&ID',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
              {
                stageNumber: 3,
                description: 'Routing as per first stress requirement',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
              {
                stageNumber: 4,
                description: 'Line update as per vendor data',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
              {
                stageNumber: 5,
                description: 'Routing as per fine stress & support pl',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
              {
                stageNumber: 6,
                description: 'Non critical lines pipe support placed',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
              {
                stageNumber: 7,
                description: 'Line ready for extraction',
                completedDate: null,
                updatedBy: null,
                updatedAt: null,
                completed: false,
                userName: null,
              },
            ],
            createdBy: uid,
            createdAt: new Date().toISOString(),
            projectId: qs.parse(location.search, { ignoreQueryPrefix: true })
              .pid,
          });
        });

        await batch.commit();
        setProcessing(true);
        return;
      };

      reader.readAsBinaryString(acceptedFiles[0]);
    } else {
      setFileTypeError(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    multiple: false,
  });

  return (
    <>
      <div className='container my-2'>
        <div className='alert alert-danger' role='alert'>
          Upload files here only after getting approval from Admin. Only
          template files need to be uploaded. Contact IT team for the template
          file.
        </div>
      </div>
      <FileUploadWrapper>
        <UploadDivider
          className='d-flex flex-column justify-content-center align-items-center'
          {...getRootProps()}
          loading={loading.toString()}
          style={{
            border: `${isDragActive ? '2px dashed #0747a6' : null}`,
          }}
        >
          <p className='theme-color'>Drag & Drop file here to upload</p>

          <input
            type='file'
            name='file'
            id='file'
            style={{ display: 'none' }}
            {...getInputProps()}
          />
          <button type='button' className='btn btn-outline-success'>
            choose file
          </button>

          {fileTypeError ? (
            <p className='py-2 text-danger'>
              Only excel file type is supported.
            </p>
          ) : null}
        </UploadDivider>
        <div
          className={
            loading && !processing
              ? 'd-flex flex-column justify-content-center align-items-center loader'
              : 'd-none'
          }
        />
        <div
          className={
            loading && processing
              ? 'd-flex flex-column justify-content-center align-items-center'
              : 'd-none'
          }
          style={{ height: '100%', padding: '0 10px' }}
        >
          <img style={{ width: '150px', height: '150px' }} src={tick} alt='' />
          <p className='text-primary text-center'>
            Line numbers uploaded successfully !
          </p>
        </div>
      </FileUploadWrapper>
    </>
  );
};

export default withRouter(Upload);
