import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { FireSQL } from 'firesql';

const firebaseConfig = {
  apiKey: 'AIzaSyAc0aWXtZGCx4fwr-lHLSkJyr8XjaeGlCA',
  authDomain: 'engineering-tracker.firebaseapp.com',
  databaseURL: 'https://engineering-tracker.firebaseio.com',
  projectId: 'engineering-tracker',
  storageBucket: 'engineering-tracker.appspot.com',
  messagingSenderId: '966068623569',
  appId: '1:966068623569:web:05e4e19448f602d9780df0',
  measurementId: 'G-TZ8G55EQQT',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const fireSQL = new FireSQL(firestore);
