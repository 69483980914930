import React, { useState, useEffect, useContext } from 'react';
import hold from '../../../assets/hold.svg';
import $ from 'jquery';
import { Edit, XCircle } from 'react-feather';
import { firestore } from '../../../firebase/firebase.utils';
import swal from 'sweetalert';
import ReactTooltip from 'react-tooltip';
import { ProjectContext } from '../../../context/projectContext';

const Hold = ({
  lineData,
  handleHold,
  docid,
  updateNewHoldState,
  handleHoldOnDelete,
}) => {
  const { projectName } = useContext(ProjectContext);

  const [holdData, setHoldData] = useState([]);
  const [newHold, setNewHold] = useState({
    tagno: '',
    remarks: '',
    description: '',
  });
  const [disableButton, setDisableButton] = useState(false);
  const [updateIndex, setUpdateIndex] = useState('');

  useEffect(() => {
    if (lineData) {
      setHoldData(holdData);
    }
    return;
  }, [lineData, newHold]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewHold({ ...newHold, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableButton(true);
    handleHold(newHold);
    setDisableButton(false);
    resetValues();
  };

  const resetValues = () => {
    setNewHold({
      tagno: '',
      remarks: '',
      description: '',
    });
  };

  const handleEdit = (holdDetails, i) => {
    setNewHold({ ...newHold, ...holdDetails });
    setUpdateIndex(i);
    $('#EditModal').modal('toggle');
  };

  const handleDelete = (holdDetails, i) => {
    let newBeforeDelete = [...lineData];
    swal({
      title: 'Are you sure?',
      text: `Once you mark this hold as closed, you cannot reopen them.`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        newBeforeDelete[i].status = true;
        await firestore
          .collection(projectName.toLowerCase())
          .doc(docid)
          .update({
            hold: newBeforeDelete,
          });
        handleHoldOnDelete(newBeforeDelete);
      }
    });
  };

  const submitOnEdit = (e) => {
    e.preventDefault();
    setDisableButton(true);
    if (updateIndex !== '') {
      firestore
        .collection(projectName.toLowerCase())
        .doc(docid)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            let updateHold = doc.data().hold;
            updateHold[updateIndex] = newHold;

            try {
              await firestore
                .collection(projectName.toLowerCase())
                .doc(docid)
                .update({ hold: updateHold });

              updateNewHoldState(updateHold);

              $('#EditModal').modal('toggle');

              setNewHold({
                tagno: '',
                remarks: '',
                description: '',
              });
              setUpdateIndex('');
              setDisableButton(false);
            } catch (e) {
              setDisableButton(false);
              console.log(`error occured while updating the hold`);
            }
          }
        })
        .catch((e) => {
          console.log(`error occured while fetching hold details`);
          setDisableButton(false);
        });
    }
  };

  return (
    <div>
      <div
        className='modal fade'
        id='EditModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='holdModal'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <form onSubmit={submitOnEdit}>
              <div className='modal-header'>
                <h4
                  className='modal-title font-weight-bolder'
                  id='holdModalLabel'
                >
                  Modify Hold
                </h4>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={resetValues}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='container'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label htmlFor='pname'>Tag Number</label>
                      <input
                        type='text'
                        className='form-control'
                        name='tagno'
                        value={newHold.tagno}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='pname'>Hold Remarks</label>
                      <input
                        type='text'
                        name='remarks'
                        value={newHold.remarks}
                        className='form-control'
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-12'>
                      <label htmlFor='pname'>Hold Description</label>
                      <textarea
                        value={newHold.description}
                        onChange={handleChange}
                        className='form-control'
                        name='description'
                        id='modify'
                        cols='30'
                        rows='5'
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                  onClick={resetValues}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-darkgreen'
                  disabled={disableButton}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='holdModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='holdModal'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <form onSubmit={handleSubmit}>
              <div className='modal-header'>
                <h4
                  className='modal-title font-weight-bolder'
                  id='holdModalLabel'
                >
                  Create Hold
                </h4>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={resetValues}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='container'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label htmlFor='pname'>Tag Number</label>
                      <input
                        type='text'
                        className='form-control'
                        name='tagno'
                        value={newHold.tagno}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='pname'>Hold Remarks</label>
                      <input
                        type='text'
                        name='remarks'
                        value={newHold.remarks}
                        className='form-control'
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-12'>
                      <label htmlFor='pname'>Hold Description</label>
                      <textarea
                        value={newHold.description}
                        onChange={handleChange}
                        className='form-control'
                        name='description'
                        id=''
                        cols='30'
                        rows='5'
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                  onClick={resetValues}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-darkgreen'
                  disabled={disableButton}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='bg-white py-3 rounded my-3'>
        <div className='d-flex px-3  justify-content-between'>
          <h3>Hold Details</h3>
          <button
            className='btn btn-darkgreen btn-lg'
            data-toggle='modal'
            data-target='#holdModal'
          >
            Create Hold
          </button>
        </div>

        {!lineData ? (
          <div className='text-center'>
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : lineData.length === 0 ? (
          <div>
            <div className='d-flex justify-content-center'>
              <img
                src={hold}
                className='img-fluid'
                width='140px'
                height='110px'
                alt='hold illustration'
              />
            </div>
            <div className='text-center'>
              <h5>No holds created</h5>
            </div>
          </div>
        ) : (
          <div className='table-responsive mt-3'>
            <table className='table table-sm table-hover mb-0 hold-table'>
              <tbody>
                <tr className='py-2 m-0'>
                  <th scope='col'>
                    <span className='text-muted'>Tag No</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Remarks</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Description</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Status</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Actions</span>
                  </th>
                </tr>
                {lineData.map((hold, i) => {
                  return (
                    <tr key={i}>
                      <td>{hold.tagno}</td>
                      <td>{hold.remarks}</td>
                      <td>{hold.description}</td>
                      <td>
                        {!hold.status ? (
                          <span className='badge badge-danger py-2 px-3 badge-pill'>
                            Open
                          </span>
                        ) : (
                          <span className='badge badge-success py-2 px-3 badge-pill'>
                            Closed
                          </span>
                        )}
                      </td>
                      <td className='d-flex'>
                        <button
                          onClick={() => handleEdit(hold, i)}
                          disabled={hold.status}
                          className='mr-2 mb-2 btn btn-light btn-sm'
                        >
                          <Edit
                            size={16}
                            color={hold.status ? 'grey' : 'blue'}
                            data-tip
                            data-for='edit'
                            className='feather'
                          />
                          <ReactTooltip id='edit'>
                            <span>edit</span>
                          </ReactTooltip>
                        </button>
                        <button
                          onClick={() => handleDelete(hold, i)}
                          disabled={hold.status}
                          className='mr-2 mb-2 btn btn-light btn-sm py-2 px-4'
                        >
                          {!hold.status ? ' Close Hold' : 'Closed'}
                          <ReactTooltip id='delete'>
                            <span>mark as closed</span>
                          </ReactTooltip>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hold;
