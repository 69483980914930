import React, { useState } from 'react';
import hold from '../../../assets/submission.svg';

const Submission = ({ handleSubmission, lineData }) => {
  const [newSubmission, setNewSubmission] = useState({
    title: '',
    revision: '0',
    submissionDate: '',
    remarks: '',
  });
  const [disableButton, setDisableButton] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSubmission({ ...newSubmission, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableButton(true);
    handleSubmission(newSubmission);

    setDisableButton(false);
    resetValues();
  };
  const resetValues = () => {
    setNewSubmission({
      title: '',
      revision: '',
      submissionDate: '',
      remarks: '',
    });
  };
  return (
    <div>
      <div
        className='modal fade'
        id='submissionModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='submissionModal'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <form onSubmit={handleSubmit}>
              <div className='modal-header'>
                <h4
                  className='modal-title font-weight-bolder'
                  id='holdModalLabel'
                >
                  Submission
                </h4>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={resetValues}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='container'>
                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label htmlFor='pname'>Document Title</label>
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={newSubmission.title}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label htmlFor='pname'>Revision No</label>
                      <select
                        value={newSubmission.revision}
                        className='form-control'
                        name='revision'
                        onChange={handleChange}
                        id='exampleFormControlSelect1'
                      >
                        <option defaultValue value='0'>
                          0
                        </option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                        <option value='11'>11</option>
                        <option value='12'>12</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='pname'>Date of Submission</label>
                      <input
                        type='date'
                        name='submissionDate'
                        value={newSubmission.submissionDate}
                        className='form-control'
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-12'>
                      <label htmlFor='pname'>Remarks </label>
                      <textarea
                        value={newSubmission.remarks}
                        onChange={handleChange}
                        className='form-control'
                        name='remarks'
                        id='remarks'
                        cols='30'
                        rows='5'
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                  onClick={resetValues}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-darkgreen'
                  disabled={disableButton}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className='bg-white py-3 rounded my-3'>
        <div className='d-flex px-3  justify-content-between'>
          <h3>Submissions</h3>
          <button
            className='btn btn-darkgreen btn-lg'
            data-toggle='modal'
            data-target='#submissionModal'
          >
            New Submission
          </button>
        </div>
        {!lineData ? (
          <div className='text-center'>
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : lineData.length === 0 ? (
          <div>
            <div className='d-flex justify-content-center'>
              <img
                src={hold}
                className='img-fluid'
                width='140px'
                height='110px'
                alt='hold illustration'
              />
            </div>
            <div className='text-center'>
              <h5>No submissions made</h5>
            </div>
          </div>
        ) : (
          <div className='table-responsive mt-3'>
            <table className='table table-sm table-hover mb-0 hold-table'>
              <tbody>
                <tr className='py-2 m-0'>
                  <th scope='col'>
                    <span className='text-muted'>Doc Title</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Revision No</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Remarks</span>
                  </th>
                  <th scope='col'>
                    <span className='text-muted'>Submission Date</span>
                  </th>
                </tr>
                {lineData.map((submission, i) => {
                  return (
                    <tr key={i}>
                      <td>{submission.title}</td>
                      <td>{submission.revision}</td>
                      <td>{submission.remarks}</td>
                      <td>{submission.submissionDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Submission;
