import React, { useRef, useEffect } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import { Search } from 'react-feather';

const AutoComplete = ({ hits, currentRefinement, refine, location }) => {
  const handleClick = () => {
    refine(null);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          refine(null);
        }
      }

      var currentFocus = -1;
      let inp = document.getElementById('lineno');
      inp.addEventListener('keydown', function (e) {
        var x = document.getElementById('autocompleteItems');
        if (x) x = x.getElementsByTagName('a');

        if (e.keyCode == 40) {
          currentFocus++;
          addActive(x);
        } else if (e.keyCode == 38) {
          currentFocus--;
          addActive(x);
        } else if (e.keyCode == 13) {
          if (currentFocus > -1) {
            if (x) {
              x[currentFocus].click();
              refine(null);
            }
          }
        }
      });

      const addActive = (x) => {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = x.length - 1;
        x[currentFocus].classList.add('autocomplete-active');
      };

      const removeActive = (x) => {
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove('autocomplete-active');
        }
      };

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
      <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
        <div className='autocomplete' style={{ width: '250px' }}>
          <div className='form-group icon-input'>
            <Search size={24} className='feather' />
            <input
              style={{ width: '250px' }}
              className='form-control'
              id='lineno'
              type='search'
              name='lineno'
              placeholder='line number'
              value={currentRefinement}
              onChange={(event) => refine(event.currentTarget.value)}
            />
          </div>

          <div id='autocompleteItems' className='autocompleteItems'>
            {currentRefinement
              ? hits.map((hit, i) => (
                  <React.Fragment key={i}>
                    <Link
                      onClick={() => refine(null)}
                      className='text-decoration-none link'
                      to={
                        location.pathname.split('/').length === 2
                          ? `dashboard/line/${hit.lineId}?pid=${
                              qs.parse(location.search, {
                                ignoreQueryPrefix: true,
                              }).pid
                            }`
                          : `/dashboard/line/${hit.lineId}?pid=${
                              qs.parse(location.search, {
                                ignoreQueryPrefix: true,
                              }).pid
                            }`
                      }
                    >
                      <p className='text-dark mb-1'>{hit.lineNumber}</p>
                    </Link>
                  </React.Fragment>
                ))
              : null}
          </div>
        </div>
      </form>
    </div>
  );
};

export default withRouter(connectAutoComplete(AutoComplete));
