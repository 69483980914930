import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { firestore } from '../../firebase/firebase.utils';
import { withRouter, NavLink } from 'react-router-dom';
import qs from 'qs';
import { Home, Tool, PieChart, Award, Upload } from 'react-feather';

const Navbar = ({ location }) => {
  const [projectDetails, setProjectDetails] = useState({
    name: null,
    description: null,
    id: null,
  });

  useEffect(() => {
    firestore
      .collection('projects')
      .doc(`${qs.parse(location.search, { ignoreQueryPrefix: true }).pid}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProjectDetails({
            ...projectDetails,
            name: doc.data().projectName,
            description: doc.data().description,
            id: qs.parse(location.search, { ignoreQueryPrefix: true }).pid,
          });
        }
      });
  }, [location.search, projectDetails]);
  return (
    <SkeletonTheme color='#d2d0d0' highlightColor='#ffffff'>
      <div className='project-heading'>
        <div className='project-heading-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12 mx-auto'>
                <div className='d-inline-flex align-items-center org-link pb-3'>
                  <div className='avatar avatar-lg mr-3'>
                    <span className='avatar-initial'>
                      {projectDetails.name ? projectDetails.name[0] : null}
                    </span>
                  </div>

                  <div className='org-heading'>
                    <h5 className='mb-0'>
                      {projectDetails.name || <Skeleton width={100} />}
                    </h5>
                    <p className='mb-0'>
                      {projectDetails.description || <Skeleton width={300} />}
                    </p>
                  </div>
                </div>
                <div className='pb-3 d-flex justify-content-between'>
                  <ul className='nav nav-pills sort-wrapper'>
                    <li className='nav-item'>
                      <NavLink
                        activeClassName='active'
                        exact
                        className='nav-link hn-nav-latest'
                        to={`/dashboard?pid=${projectDetails.id}`}
                      >
                        <Home size={24} className='feather' />
                        Home
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        className='nav-link'
                        activeClassName='active'
                        exact
                        to={`/dashboard/tools?pid=${projectDetails.id}`}
                      >
                        <Tool size={24} className='feather' />
                        Tools
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        className='nav-link'
                        activeClassName='active'
                        exact
                        to={`/dashboard/reports?pid=${projectDetails.id}`}
                      >
                        <PieChart size={24} className='feather' />
                        Reports
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        className='nav-link'
                        activeClassName='active'
                        exact
                        to={`/dashboard/completed?pid=${projectDetails.id}`}
                      >
                        <Award size={24} className='feather' />
                        Completed
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink
                        className='nav-link'
                        to={`/dashboard/upload?pid=${projectDetails.id}`}
                        activeClassName='active'
                        exact
                      >
                        <Upload size={24} className='feather' />
                        Upload
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default withRouter(Navbar);
