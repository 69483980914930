import React, { useState, useContext } from 'react';
import logo from '../assets/LOGO.svg';
import { auth } from '../firebase/firebase.utils';
import { AuthContext } from '../components/Auth/Auth';
import { withRouter, Redirect } from 'react-router-dom';

const Login = ({ history }) => {
  const [authUser, setAuthUser] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { email, password } = authUser;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      setAuthUser({ email: '', password: '' });
      history.push('/manage/project');
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const { user } = useContext(AuthContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAuthUser({ ...authUser, [name]: value });
  };

  if (user.currentUser) {
    return <Redirect to='/manage/project' />;
  }

  return (
    <div className='container'>
      <div className='row h-100v'>
        <div className='col-sm-12 col-md-7 col-lg-4 mx-auto my-auto'>
          <div className='my-5'>
            <div className='text-center'>
              <img src={logo} alt='company logo' />
            </div>
            <h3 className='text-center'>ENGINEERING TRACKER</h3>
            <div className='card card-body mt-4'>
              <form onSubmit={handleSubmit}>
                <div className='form-group icon-input'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-user'
                    aria-hidden='true'
                  >
                    <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                    <circle cx='12' cy='7' r='4'></circle>
                  </svg>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Email'
                    autoComplete='email'
                    name='email'
                    value={authUser.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='form-group icon-input'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-eye'
                    aria-hidden='true'
                  >
                    <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'></path>
                    <circle cx='12' cy='12' r='3'></circle>
                  </svg>

                  <input
                    autoComplete='off'
                    placeholder='Password'
                    className='form-control'
                    type='password'
                    name='password'
                    id='user_password'
                    value={authUser.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='flex-between'>
                  <a data-remote='true' href='/forgot_password'>
                    Forgot Password?
                  </a>
                  <button
                    disabled={loading}
                    type='submit'
                    className='btn btn-primary px-4 py-2'
                  >
                    LOGIN
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
