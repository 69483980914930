import React, { useEffect } from 'react';
import { MoreHorizontal, Trash } from 'react-feather';

import moment from 'moment';

const CommentDisplay = ({ allComments, handleCommentDelete }) => {
  useEffect(() => {}, [allComments]);

  return (
    <>
      {allComments.map((comment, i) => {
        return (
          <div key={i} className='card card-body flex-row p-3 pb-3 comment-box'>
            <div className='avatar mr-2'>
              <span className='avatar-initial'>
                {comment.createdBy ? comment.createdBy[0] : null}
              </span>
            </div>
            <div className='w-100 overflow'>
              <div className='flex-between mb-2'>
                <div>
                  <h6 className='mb-0 text-dark'>{comment.createdBy}</h6>
                  <div className='text-muted mb-0 small'>
                    {moment(comment.createdAt.toDate(), 'YYYYMMDD').fromNow()}
                  </div>
                </div>

                <div className='dropdown pl-2'>
                  <span
                    id='commentaction'
                    className='cp btn btn-light btn-sm'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <MoreHorizontal size={24} />
                  </span>
                  <div
                    className='dropdown-menu dropdown-menu-right'
                    aria-labelledby='bucketaction'
                  >
                    <div
                      className='cp dropdown-item'
                      onClick={() => handleCommentDelete(i)}
                    >
                      <span className='pr-1'>
                        <Trash size={24} className='feather' />
                      </span>
                      Remove
                    </div>
                  </div>
                </div>
              </div>
              <div className='pre-line-box'>
                <p>{comment.message}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CommentDisplay;
