import React from 'react';
import CompletedChart from '../../components/UserDashboard/Charts/CompletedChart';

const Dashboard = () => {
  return (
    <div className='container my-4'>
      <div className='row'>
        <CompletedChart />
      </div>
    </div>
  );
};

export default Dashboard;
