import React, { useState, useContext } from 'react';
import { Search } from 'react-feather';
import { fireSQL } from '../../../firebase/firebase.utils';
import { ProjectContext } from '../../../context/projectContext';

const ToolSearch = ({ handleSearch, setInitialSearch }) => {
  const { projectName } = useContext(ProjectContext);
  const [searchInput, setSearchInput] = useState('');

  const handleQuery = (e) => {
    e.preventDefault();
    setInitialSearch('Searching');
    if (searchInput === '' || searchInput.trim() === '') {
      return;
    }
    const size = isNaN(searchInput) ? `'${searchInput}'` : searchInput;
    const searchIn = isNaN(searchInput)
      ? `${searchInput.toString().trim()}`
      : searchInput;

    const searchPromise = fireSQL.query(
      `
        SELECT * from ${projectName.toLowerCase()} WHERE lineNumber = '${searchIn}' OR fluidCode = '${searchIn}' OR size = ${size} OR pipingClass = '${searchIn}'
      `,
      { includeId: 'id' }
    );
    searchPromise
      .then((res) => handleSearch(res))
      .catch((err) => console.log(err));
  };
  return (
    <form className='icon-input' onSubmit={handleQuery}>
      <Search size={100} className='search-icon feather' />

      <input
        style={{ paddingLeft: '60px' }}
        className='tools-search form-control'
        type='text'
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder='LineNo/FCode/PClass/Size'
      />
      <button type='submit' className='btn btn-darkgreen btn-lg'>
        SEARCH
      </button>
    </form>
  );
};

export default ToolSearch;
