import React, { useState, useEffect, useContext } from 'react';
import LineDetails from '../../components/UserDashboard/LineUpdate/LineDetails';
import LineStages from '../../components/UserDashboard/LineUpdate/LineStages';
import Hold from '../../components/UserDashboard/LineUpdate/Hold';
import Submission from '../../components/UserDashboard/LineUpdate/Submission';
import Comment from '../../components/UserDashboard/LineUpdate/Comment';
import { firestore } from '../../firebase/firebase.utils';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert';
import firebase from 'firebase/app';
import { AuthContext } from '../../components/Auth/Auth';
import { ProjectContext } from '../../context/projectContext';

const LineUpdate = ({ location }) => {
  const { projectName } = useContext(ProjectContext);

  const [lineData, setLineData] = useState({});
  const [loading, setLoading] = useState(true);

  const [docid, setDocId] = useState('');

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (projectName !== '') {
      const docId = location.pathname.split('/')[3];
      setDocId(docId);

      firestore
        .collection(projectName.toLowerCase())
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setLineData(doc.data());
            setLoading(false);
            return;
          } else {
            console.log('Line Not Found');
            return;
          }
        });
    }
  }, [location, projectName]);

  const handleSheetUpdate = async (sheetNo) => {
    const docId = location.pathname.split('/')[3];

    setLineData({ ...lineData, noOfSheets: sheetNo });
    firestore
      .collection(projectName.toLowerCase())
      .doc(docId)
      .update({ noOfSheets: sheetNo })
      .then((res) => {
        $('#sheetsModal').modal('toggle');
        return true;
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleComplete = (stageNumber, setDisableButton) => {
    const docId = location.pathname.split('/')[3];
    firestore
      .collection(projectName.toLowerCase())
      .doc(docId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let stages = doc.data().stages;
          let error = false;
          for (let i = 0; i < stageNumber - 1; i++) {
            if (!stages[i].completed) {
              error = true;
              break;
            }
          }
          if (error) {
            swal(
              'Previous Stage Not Completed!',
              'Complete all your previous stages, before marking this stage as completed.',
              'error'
            );
            setDisableButton(false);
          } else {
            swal({
              title: 'Are you sure?',
              text: `Once the stage is marked as completed, this action cannot
                be reversed.`,
              icon: 'warning',
              buttons: true,
            }).then(async (ok) => {
              if (ok) {
                let tempStages = lineData.stages;
                tempStages[stageNumber - 1].completed = true;
                tempStages[
                  stageNumber - 1
                ].completedDate = firebase.firestore.Timestamp.fromDate(
                  new Date()
                );
                tempStages[stageNumber - 1].updatedAt =
                  tempStages[stageNumber - 1].completedDate;

                tempStages[stageNumber - 1].updatedBy = user.currentUser.uid;
                tempStages[stageNumber - 1].userName = await (
                  await firestore
                    .collection('users')
                    .doc(user.currentUser.uid)
                    .get()
                ).data().userName;
                await firestore
                  .collection(projectName.toLowerCase())
                  .doc(docId)
                  .update({
                    stages: tempStages,
                  });
                setLineData({ ...lineData, stages: tempStages });
              } else {
              }
              setDisableButton(false);
            });
          }
        }
      });
  };

  const handleHold = async (newHold) => {
    const docId = location.pathname.split('/')[3];
    let tempHold = lineData.hold;
    tempHold.push(newHold);
    setLineData({ ...lineData, hold: tempHold });
    await firestore
      .collection(projectName.toLowerCase())
      .doc(docId)
      .update({ hold: firebase.firestore.FieldValue.arrayUnion(newHold) });

    $('#holdModal').modal('toggle');
  };

  const handleSubmission = async (newSubmission) => {
    const docId = location.pathname.split('/')[3];
    let tempSubmission =
      lineData && lineData.submission ? lineData.submission : [];
    tempSubmission.push(newSubmission);
    setLineData({ ...lineData, submission: tempSubmission });
    await firestore
      .collection(projectName.toLowerCase())
      .doc(docId)
      .update({
        submission: firebase.firestore.FieldValue.arrayUnion(newSubmission),
      });
    $('#submissionModal').modal('toggle');
  };

  const handleHoldOnDelete = (newHoldAfterDelete) => {
    setLineData({ ...lineData, hold: newHoldAfterDelete });
  };
  const updateNewHoldState = (newHoldState) => {
    setLineData({ ...lineData, hold: newHoldState });
  };

  return (
    <div className='container my-3'>
      <div
        className='modal fade'
        id='stageAlertModal'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='stageAlertModal'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header d-block text-center'>
              <h6
                className='modal-title font-weight-bolder text-center'
                id='stageAlertModalLabel'
              >
                PREVIOUS STAGES NOT COMPLETED
              </h6>
            </div>
            <div className='modal-body'>
              <div className='container'>
                <div className='form-group row'>
                  <div className='col-md-12 text-center'>
                    <p className='lead'>
                      Once the stage is marked as completed, this action cannot
                      be reversed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='pb-2 d-block text-center'>
              <button
                type='button'
                className='btn btn-danger btn-lg px-4'
                data-dismiss='modal'
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <LineDetails
        lineData={lineData}
        loading={loading}
        handleSheetUpdate={handleSheetUpdate}
      />
      <LineStages
        markComplete={handleComplete}
        lineData={lineData.stages}
        loading={loading}
      />

      <Hold
        updateNewHoldState={updateNewHoldState}
        docid={docid}
        handleHold={handleHold}
        lineData={lineData.hold}
        loading={loading}
        handleHoldOnDelete={handleHoldOnDelete}
      />
      <Submission
        lineData={lineData && lineData.submission ? lineData.submission : []}
        loading={loading}
        handleSubmission={handleSubmission}
      />
      <Comment lineData={lineData.comment} loading={loading} />
    </div>
  );
};

export default withRouter(LineUpdate);
